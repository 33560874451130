import { Modal } from "react-bootstrap";

import DeleteAccount from "../assets/assets_img/account_delete.webp"
import { getApi } from "../Helpers/Axios";

const DeleteConformation = ({show, onHide, title, url}) => {

    const handleDelete = async () => {
        await getApi(url, true).then((response) => {
            if(response.status === 200) {
                onHide();
            }
        })
    }

    return (<Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="logout-account-model">
            <div class="logo-icon">
                <img alt="logout" src={DeleteAccount}/>
            </div>
            <p class="title font-weight-400">{`Are you sure you want to delete ${title} ?`}</p>
            <div class="logout-btns">
                <div class="w-100" data-tooltip-id="" data-tooltip-content="">
                    <button class="cancel-btn" onClick={onHide}>Cancel</button>
                </div>
                <div class="w-100" data-tooltip-id="" data-tooltip-content="">
                    <button class="logout-btn" onClick={handleDelete}>{`Delete`}</button>
                </div>
            </div>
        </Modal.Body>
    </Modal>)
}

export {DeleteConformation}; 