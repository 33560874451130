
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import {Table} from 'react-bootstrap';

// SVGs
import PlusSvg from "../../assets/assets_svg/addicon.svg";
import editSvg from "../../assets/assets_svg/edit.svg";
import deleteSvg from "../../assets/assets_svg/delete.svg";
import eyeSvg from "../../assets/assets_svg/eye.svg";
import eyeHideSvg from "../../assets/assets_svg/eyehide.svg";

import {DeleteConformation} from "../DeleteConformation";
import { getApi, postApi } from "../../Helpers/Axios";

const Banner = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);

    const [bannerData, setBannerData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [delId, setDelId] = useState(0);
    
    // Pagination assets
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [searchData, setSearchData] = useState('');
    
    useEffect(() => {
        setIsLoadingData(true);
        fetchBanner();
    }, [currentPage, searchData])

    useEffect(() => {
        if(!modalShow) {
            fetchBanner();
        }
    }, [modalShow])

    const fetchBanner = async () => {
        let url = searchData !== '' ? `get-banner?page=${currentPage}&search=${searchData}` : `get-banner?page=${currentPage}`
        await getApi(url, true).then((response) => {
            if(response.status === 200) {
                setBannerData(response?.data);
                setDataCount(response?.dataCount);
                setTotalPage(response?.totalPage);
                setIsLoadingData(false);
            } else {
                setIsLoadingData(false);
            }
        })
    }

    const handleStatus = async (id, status) => {
        let url = `status-banner?status_id=${id}`;
        let bodyData = {"status": status === 1 ? 0 : 1}
        await postApi(url, bodyData, true)
            .then(async(response) => {
                if (response.status === 200) {
                    setBannerData((prevBannerData) => 
                        prevBannerData.map(banner =>
                            banner.id === id ? { ...banner, status: bodyData?.status } : banner
                        )
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    return(
        <>
            <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 breadcrumbs"><span><Link to={'/dashboard'}>Dashboard</Link> /</span> Banner</p>
                <div className="d-flex justify-content-between align-items-center">
                    <input type="search" placeholder="Searching...." class="search-input me-3" name="title" value={searchData} onChange={(e) => setSearchData(e.target.value)} />
                    <Link to={'/add-banner'}><button className="theme-icon-btn float-end px-3"><img src={PlusSvg} alt="setting icon" />Add</button></Link>
                </div>
            </div>

            <div className="data-table mt-3">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Banner</th>
                            <th>Phones</th>
                            <th>Phone name</th>
                            <th className="text-center" style={{width: "140px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !isLoadingData ? bannerData?.length ? bannerData?.map((val, key) => {
                                return(
                                    <tr key={key}>
                                        <td>{val?.id}</td>
                                        <td>
                                            <div className="data-image">
                                                <img src={`${process.env.REACT_APP_UPLOAD_URL+'banners/'+val.banner}`} alt="" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="data-phones-image">
                                                <img src={`${process.env.REACT_APP_UPLOAD_URL+'phones/'+val.phones}`} alt="" />
                                            </div>
                                        </td>
                                        <td>{val?.phone_name}</td>
                                        <td>
                                            <div className="data-action">
                                                <span className="action-icon" onClick={() => navigate(`/add-banner?edit_id=${val.id}`)}><img src={editSvg} alt="" /></span>
                                                <span className="action-icon" onClick={() => {setModalShow(true); setDelId(val.id)}}><img src={deleteSvg} alt="" /></span>
                                                <span className="action-icon" onClick={() => handleStatus(val?.id, val?.status)}>
                                                    {val.status ? <img src={eyeSvg} alt="" /> : <img src={eyeHideSvg} alt="" />}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr className="text-center">
                                <td colSpan={5}>Not found</td>
                            </tr>) : <tr>
                                <td colSpan={5} className="text-center">Loading...</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>

            <div className='footer-pagination'>
                <ul className='paginations d-flex justify-content-end list-unstyled gap-2'>
                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage < 1 ? 'disabled' :false} onClick={() => handlePageChange(1)}>{'<<'}</li>
                            <li className={`pages-item ${currentPage === 1 ? 'disabled' : ''}`} disabled={currentPage < 1 ? 'disabled':false} onClick={() => handlePageChange(currentPage - 1)}>{'<'}</li>
                        </>
                    )}
                    {
                        dataCount > 10 && Array.apply(null, Array(totalPage)).map((val, key) => {
                            return <li key={key} className={`pages-item ${(currentPage === (key + 1) && currentPage !== 1) ? 'active' : ''}`} onClick={() => handlePageChange(key + 1)}>{key + 1}</li>
                        })
                    }
                    {dataCount > 10 && (
                        <>
                            <li className={`pages-item ${currentPage === parseInt(totalPage) ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 1)}>{'>'}</li>
                            <li className={`pages-item ${currentPage === parseInt(totalPage) ? 'disabled' : ''}`} onClick={() => handlePageChange(totalPage)}>{'>>'}</li>
                        </>
                    )}
                </ul>
            </div>
            
            <DeleteConformation 
                show={modalShow} 
                onHide={() => setModalShow(false)}
                title='banner'
                url={`delete-banner?del_id=${delId}`}
            />

        </>
    )
}

export default Banner;