import React, { useEffect, useState } from 'react'
import PrevBannerSvg from '../../assets/assets_svg/prevbanner.svg'
import { Link, useLocation, useNavigate, useSearchParams  } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApi, postApi } from "../../Helpers/Axios";

const Addform = () => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get('edit_id')) {
            handleEdit()
        }
    }, [searchParams.get('edit_id')])

    const handleEdit = async() => {
        let url = `get-task?edit_id=${searchParams.get('edit_id')}`;
        await getApi(url, true).then((response) => {
            if(response.status === 200) {
                setInitialValueData(response.data)
            }
        })
    }

    const [initialValues, setInitialValueData] = useState ({
        task_title: null,
        task: null,
        status: 0,
    });
    
    const validationSchema = Yup.object({
        task_title: Yup.string()
            .required("Task Title is required."),
        task: Yup.string()
            .required("Task URL is required.")
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            values.status = values.status ? 1 : 0;
            let url = searchParams.get('edit_id') ? `add-task?edit_id=${searchParams.get('edit_id')}` : 'add-task';
            await postApi(url, values, true)
                .then((response) => {
                    if(response.status === 200) {
                        navigate("/task");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    return (
        <div>
            <div>
                <div>
                    <p className='breadcrumbs'><Link to='/dashboard'><span>Dashboard</span></Link> / <Link to='/task'><span>Task</span></Link> / <span>Add</span></p>
                </div>
            </div>
            <div>
                <form method='post' className='add-detail-main' onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div class="form-with-lable">
                        <label class="mt-1 form_label">Task Title</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Task Title' name='task_title' onChange={handleChange} onBlur={handleBlur} value={values?.task_title} />                        
                        </div>
                    </div>
                    {(errors?.task_title && touched?.task_title) ? <span className='require-text'>{errors?.task_title}</span> : null}
                    <div class="form-with-lable mt-3">
                        <label class="form_label">Task URL</label>
                        <div class="input-box">
                            <input type='text' placeholder='Enter Task URL' name='task' onChange={handleChange} onBlur={handleBlur} value={values?.task} />                        
                        </div>
                    </div>
                    {(errors?.task && touched?.task) ? <span className='require-text'>{errors?.task}</span> : null}
                    <Form.Check
                        type="switch" id="custom-switch"
                        label="Status" className='float-end mt-2'
                        checked={values?.status} onChange={(e) => setFieldValue('status', !values?.status)}
                    />
                    <div className='text-center mt-4'>
                        <button type='submit' className='theme-btn px-4'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Addform